$(function () {
  //contact form processing
  $('form.contact-form').on('submit', function (e) {
    e.preventDefault();
    var $form = $(this);
    $($form).find('span.contact-form-respond').remove();

    //checking on empty values
    $($form)
      .find('[aria-required="true"], [required]')
      .each(function (index) {
        if (
          !$.trim($(this).val()).length ||
          (this.type === 'email' && $(this).val().indexOf('@') === -1)
        ) {
          $(this).parent().addClass('has-error');
          $(this).on('focus', function () {
            $(this).parent().removeClass('has-error');
          });
        }
      });
    //if one of form fields is empty - exit
    if ($form.find('.has-error').length) {
      return;
    }

    $(".overlay").show();

    //sending form data to PHP server if fields are not empty
    var request = $form.serialize();

    grecaptcha
      .execute("6Lcbv-4aAAAAAIqBcvLBzIchQiy6MJXj0kOq4KgL", {
        action: "contact_form",
      })
      .then(function (token) {
        request = request + "&token=" + token;

        var ajax = jQuery
          .post("contact-form.php", request)
          .always(function (data) {
            jQuery(".overlay").hide();
            jQuery($form)
              .find('[type="submit"]')
              .attr("disabled", false)
              .parent()
              .append(
                '<span class="contact-form-respond highlight">' +
                  data.message +
                  "</span>"
              );
            //cleaning form
            var $formErrors = $form.find(".form-errors");
            if (!$formErrors.length) {
              $form[0].reset();
            }
          });
      });
  });
});
